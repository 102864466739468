@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

.input-field {
  @apply bg-transparent  outline-none border-b-2 p-2 border-[#646464] text-white;
  &:-webkit-autofill { /* TODO moet nog weg gedaan worden -> zorgt voor error */

    box-shadow: 0 0 0 1000px #181818 inset;
    -webkit-text-fill-color: white;
  }
}

.input-field-light {
  @apply bg-transparent outline-none border-b-2 p-2 border-[#D1D1D1FF] ;
  &:-webkit-autofill { /* TODO moet nog weg gedaan worden -> zorgt voor error */

    box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: black;
  }
}

.btn {
  @apply bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded  items-center;

}

.beer{
  animation: goUp 1s forwards ;
}
.fadeOut{
  animation: fadeOut 1s forwards;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}


@keyframes goUp {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }

}

.stamp{
  -webkit-animation-name: pulse;
  animation-name: pulse;
  animation-duration: 1s;
  opacity: 0;
  animation-delay: 1s;
  -webkit-animation-delay:0s;
  -webkit-animation-duration:1s;
  -webkit-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

}

@keyframes pulse{
  0%{
    opacity: 0;
  }
  10%{
    opacity:.50;
    transform-origin: 50% 50%;
    transform: rotate(-2deg) scale(5);
    transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100%{
    opacity:1;
    transform: rotate(-15deg) scale(1);
  }
}


.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#99855A, #99855A);
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}



.link-underline:hover {
  background-size: 100% 1px;
  background-position: 0 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
